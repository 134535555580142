.dashboard {
  font-size: 25px;
  margin-top: 7px;
  text-align: center;
}

.main_menu {
  margin-left: 30px;
}

.loginContainer {
  background-color: gray;
  height: 243px;
  margin-left: 500px;
  margin-top: 146px;
  text-align: center;
  width: 350px;
}

.main_menu ul li {
  float: left;
  display: inline-block;
}

.main_menu ul li a {
  padding: 0 10px 0 10px;
}

.submenu {
  display: block;
  position: absolute;
  margin: 0;
  background: #ffffff;
  box-shadow: 5px 5px 5px #000000;
  border: 1px solid #cccccc;
  width: 140px;
}

.submenu li {
  border-bottom: 1px solid #cccccc;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dropdown {
  position: relative;
}